// @flow
import * as React from 'react'
import Ribbon from '../../components/Ribbon/Ribbon'
import Markdown from '../../components/Markdown/Markdown'
import type { RichTextViewModel } from '../../types/RichTextViewModel'

type Props = RichTextViewModel

type State = {}

class RichText extends React.Component<Props, State> {
  static displayName = 'RichText'
  static defaultProps = {
    area: 'wide',
  }

  render() {
    if (!this.props.source) return null

    return (
      <Ribbon clearChildMargin>
        <Markdown source={this.props.source || ''} />
      </Ribbon>
    )
  }
}

export default RichText
